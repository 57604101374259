.trip-summary {
    background-color: var(--gray);
    color: var(--darkBlue);
    padding: 10px;
    border-radius: 50px;
    margin-top: 20px;
    max-height: 60px;
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.122);
}


.trip-summary h3 {
    margin: 0px;
}

.trip-summary p {
    margin: 0px;
    margin-top: 5px;
}

.trip-summary b {
    font-family: var(--logoFont);
    font-weight: bold
}

.edit-trip-button {
    max-width: 60px;
    min-width: 60px;
    max-height: 60px;
    min-height: 60px;
    border-radius: 30px;
    border: none;
    outline: none;
    background-color: var(--darkBlue);
    font-size: 2rem;
    color: var(--gray);

}